@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');

.main div.navbar.sitesentry-subnav {
  position: absolute !important;
  margin-top: 66px !important;
  z-index: 1;
  > .subnav {
    position: absolute !important;
    top: 16px;
  }
}

/* HIDE OLD SUBNAVS */
.adblock-report.subnav,
.sitesentry-subnav,
.spt-top-menu .triangle-icon {
  display: none !important;
}
.ant-dropdown-menu-title-content > a {
  display: block !important;
  padding: 10px 50px 10px 25px !important;
}

.spt-layout {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.spt-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  width: 100%;
  .spt-content {
    height: calc(100% - 66px);
    overflow-y: scroll;
    padding: 0 var(--ant-margin-lg);
  }
  .spt-subnav {
    height: 60px;
    width: 100%;
    box-shadow: 0px 2px 5px #00000029;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 7;
    > img {
      height: 33px;
      width: 203px;
      margin-left: 30px;
    }
    .spt-top-menu {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      column-gap: 16px;
      padding-right: 32px;
    }
    .spt-top-item {
      height: 66px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      svg {
        color: #A4AFB7;
        font-size: 12px;
      }
      .spt-top-icon {
        margin-right: 8px;
        svg {
          font-size: 16px;
        }
      }
    }
  }
  &.closed .spt-subnav {
    padding-left: 60px;
  }
}



/* HANDLE LAYOUT */
.spt-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: white;
  &.gray-layout {
    background-color: #f5f5f5;
    .spt-subnav {
      background-color: white;
    }
  }

  .spt-subnav {
    background-color: #f5f5f5;
  }

  .spt-main {
    position: relative;
    overflow: hidden;
    flex: 1;
  }
  .spt-menu {
    background: #362360;
    color: white;
    width: 60px;
    height: 100%;
    transition: width 0.2s linear;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &.open {
      width: 240px;
    }
    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
  }
  .spt-items {
    div.admin-wrapper {
      position: absolute;
      bottom: 0;
    }
    padding-bottom: 100px;
  }
}

/* HANDLE Menu Toggle */
.spt-menu {
  .toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    cursor: pointer;
    box-shadow: 0px 2px 5px #00000029;
    position: relative;
    > div {
      width: 30px;
      height: 60px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      svg {
        color: #fff;
        opacity: 0.5;
      }
      &:hover svg {
        opacity: 1.0;
      }
      &.close, &.minimize {
        left: 0;
      }
      &.maximize {
        right: 0;
      }
      &.open {
        width: 60px;
        left: 0;
      }
    }
  }
  &.open .toggle {
    justify-content: flex-end;
    > div {
      width: 60px;
      &.minimize {
        right: 0;
        left: auto;
      }
    }
  }
  &.closed {
    display: block;
    width: 60px !important;
    height: 60px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
  }
}

/* HANDLE MENU ITEMS */
.spt-menu.admin-settings {
  height: 48px !important;
  width: 60px !important;
}
.spt-menu.open .spt-menu.admin-settings {
  width: 240px !important;
  > .spt-item {
    padding-left: 0px;
  }
}
.spt-menu.closed .spt-menu.admin-settings {
  display: none !important;
}
.spt-menu, .spt-menu .spt-drop-box {
  .spt-title {
    height: 56px;
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    .title-icon {
      width: 60px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: #65C582;
        font-size: 16px;
        height: 16px;
        width: 16px;
        display: block;
        path {
          fill: #C2B8D8;
        }
      }
      img {
        height: 16px;
        width: 16px;
      }
    }
    &.no-icon .title-name {
      padding: 0 16px;
    }
    .title-name {
      white-space: nowrap;
      color: #65C582;
      font-family: Oswald;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.9px;
      text-transform: uppercase;
    }
  }
  .admin-settings {
    > .spt-item > .item-icon svg,  > .spt-item > .item-name, > svg {
      color: #65C582 !important;
      font-weight: 400;
      text-transform: uppercase;
      path { fill: #65C582 !important; }
    }
  }
  .spt-item {
    height: 48px;
    width: 240px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    padding-left: 0;
    transition: padding 0.2s linear;
    text-decoration: none !important;

    .item-icon {
      width: 60px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      svg {
        color: #C2B8D8;
        font-size: 16px;
        height: 16px;
        width: 16px;
        display: block;
        path {
          fill: #C2B8D8;
        }
      }
      img {
        height: 16px;
        width: 16px;
      }
    }
    .item-name {
      // white-space: nowrap;
      color: #C2B8D8;
      font-family: Oswald;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.54px;
      display: none;

      &.status-incident {
        // Make sure to override the "active" coloring if necessary
        color: rgb(255, 72, 0) !important
      }
    }
    &:hover {
      background: rgba(255,255,255,0.2);
      .item-name {
        color: #fff;
      }
      .item-icon svg {
        color: #fff;
        path {
          fill: #fff;
        }
      }
    }
    &.no-icon .item-name {
      padding: 0 16px;
    }
  }
  &.open .spt-item {
    padding-left: 8px;
  }
  &.open {
    .title-name, .item-name {
      display: flex;
    }
  }
}

.spt-menu .spt-drop {
  .title-name, .item-name {
    display: flex !important;
  }
}

/* HANDLE DROPDOWN */
.spt-menu {
  .spt-menu-divider {
    display: block;
    content: '';
    clear: both;
    height: 1px;
    background: #FFFFFF;
    width: 100%;
    opacity: 0.2;
    margin: 8px 0;
  }
  a.spt-menu {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    position: relative;
    > svg {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      color: #C2B8D8;
      font-size: 10px;
    }
  }
  &.open a.spt-menu > svg {
    right: 16px;
  }
  .spt-drop {
    display: block;
    width: 240px;
    border-left: 1px solid transparent;
    .spt-drop-title {
      background: #6565A9;
      color: #fff !important;
      white-space: nowrap;
      color: #C2B8D8;
      font-family: Oswald;
      font-weight: 400;
      padding: 8px 16px;
      margin-bottom: 2px;

      &.dialogue {
        margin-top: 70px;
      }
    }
    .spt-drop-menu {
      .spt-item {
        padding: 12px 16px 12px 0px;
        background: #362360;
        min-height: 50px;
        height: 100%;
        margin-bottom: 1px;
        &:hover {
          background: rgb(81, 71, 114);
        }
        &.active {
          background: rgb(81, 71, 114);
          .item-name{
            color: white
          }
        }
      }
      > a {
        margin-bottom: 1px;
      }
      .spt-drop-box .spt-drop {
        margin-top: -54px !important;
      }
    }

  }
  .spt-drop-box {
    transition: left 0.2s linear;
    left: 61px !important;
    &:not(.ant-dropdown-placement-right) {
      .spt-drop {
        margin-top: -50px;
      }
    }
  }
  &.open .spt-drop-box, .spt-drop-box .spt-drop-box {
    left: 241px !important;
  }
}


.spt-dialogue {
  background: #ffffff;
  position: fixed;
  top: 60px;
  left: 60px;
  height: 56px;
  width: calc(100% - 60px);
  overflow: hidden;
  transition: left 0.2s linear;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;
  z-index: 9999;
  border-bottom: 1px solid #e5e7e9;
  > a {
    .spt-item {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  &.open {
    left: 240px;
    width: calc(100% - 240px);
  }
  &.closed {
    left: 0;
    width: 100%;
  }
  .spt-menu {
    width: auto;
    background: transparent;
  }
  > .spt-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    > svg {
      color: #4D4D4D;
      font-size: 10px;
      margin-left: 6px;
    }
  }
  .spt-item {
    height: 56px;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    padding-left: 0;
    transition: padding 0.2s linear;
    text-decoration: none !important;
    cursor: pointer;
    &.active {
      background: #e5e7e9;
    }
    .item-icon {
      display: none;
    }
    .item-name {
      white-space: nowrap;
      color: #2D2D2D;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      padding: 0 !important;
    }
    &:hover {
      background: #e5e7e9;
      > .item-name {
        color: #2D2D2D !important;
      }
    }
  }
}

.dialogue-layout.has-subnav {
  padding-top: 56px;
}

.dialogue-drop {
  .spt-drop {
    background-color: #fff;
    border-radius: 10px;
    outline: none;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    padding: 8px;
    width: 290px;
    .spt-item .item-name {
      padding: 0 16px !important;
    }
  }
  .spt-drop-title {
    display: none;
  }
  .spt-menu {
    width: auto;
    background: transparent;
  }
  a.spt-menu {
    height: 48px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    position: relative;
    > svg {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      color: #C2B8D8;
      font-size: 11px;
    }
  }
  .spt-drop-menu > a {
    display: block;
    &:hover {
      background: #f5f7f9;
      border-radius: 8px;
    }
  }
  .spt-item {
    height: 48px;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    padding-left: 0;
    transition: padding 0.2s linear;
    text-decoration: none !important;
    .item-icon {
      display: none;
    }
    .item-name {
      white-space: nowrap;
      color: #2D2D2D;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      padding: 0;
    }
    &:hover {
      background: transparent;
      > .item-name {
        color: #2D2D2D !important;
      }
    }
  }
  .dialogue-drop {
    left: 280px !important;
    .spt-drop {
      margin-top: -50px;
    }
  }
  .spt-menu.dialogue-drop {
    left: 0 !important;
  }
}


.redberry-navbar {
  background-color: #fff;
  border-radius: 10px;
  outline: none;
  margin-top: 15px;
}

.notification-bell-icon {
  position: relative;
  .count {
    position: absolute;
    top: -8.5px;
    right: -8.5px;
    background-color: #C02822;
    color: #fff;
    width: 23px;
    height: 23px;
    padding: 2px;
    border-radius: 11.5px;
    z-index: 2;
    font-size: 11px;
    line-height: 17px;
    text-align: center;

    .ant-spin-dot-item {
      background-color: white;
    }
  }
  .isvg {
    display: flex;
    z-index: 1;
    svg {
      width: 20px;
      height: 26px;
      color: #411F90;
    }
  }
}

#candu-div {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999999999999;
}