@import "./diagnose.css.less";

#avocado .vendor_trace_dashboard {
  max-width: inherit;

  .diagnose_dashboard-main-filters-block {
    position: inherit;
  }

  .vendor_trace_filter{
    column-gap: 1% !important;

    .ant-form-item {
      max-width: 100%;
    }
  }
  .diagnose_filters, .vendor_trace_container {
    .ant-select {
      line-height: 36px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      line-height: 36px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      padding-right: 0;
    }
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
      padding-right: 30px;
    }
  }

  .vendor-trace-custom-link {
    stroke-width: 15;
    fill: none
  }

  .vendor-trace-node-item-container-foreignObject{
    overflow: visible;
    background-color: transparent;
  }
  .vendor-trace-node-item-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    background-color: transparent;
  }
  .node-children-expand {
    height: calc(100% - 8px);
    aspect-ratio: 1 / 1;
    background-color: white;
    border: 1px solid @primary-color;
    border-radius: 5px;
    color: @primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -20px;
    top: 4px;
    cursor: pointer;
    margin-left: -8px;
  }

  .vendor-trace-node-item {
    height: 100%;
    // width hardcoded for purposes of safari browther bugs
    min-width: 80px;
    max-width: 80px;
    width: 80px;
    font-size: 14px;
    padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    border: 2px solid;
    border-radius: 5px;
    box-shadow: 0px 2px 4px #00000029;
    &:hover{
      cursor: pointer;
    }
  }
}

.vendor_trace_container {
  border: 1px solid @border-color;
  border-radius: 18px;
  min-height: 60vh;
  background-color: #fff;
  .avocado.ant-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .vendorTraceTreeDescriptionWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .show-more-layers-buttons {
      display: flex;
      .show-more-layers-button {
        background-color: @primary-medium-color;
        border: none;
        &:first-child {
          margin-right: 5px;
        }
        .anticon {
          padding: 0
        }
        svg {
          fill: white
        }
        &:disabled {
          background-color: #EBEBEB;
          svg {
            fill: #CCCCCC;
          }
        }
      }
    }
    .vendorTraceTreeTrackingTech{
      padding-left: 285px;
    }
  }
  #vendorTraceTreeWrapper{
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    .title-row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      div {
        font-weight: 600;
        padding-right: 5px;
      }
      .anticon-info-circle svg{
        fill: @info-circle-color;
      }
    }
    .sliding-filters-layout {
      background-color: #fff;
      box-shadow: 0 3px 6px #00000029;
      border: 1px solid #6565A987;
      border-radius: 6px;
      position: fixed;
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      transition: ease all 0.3s;
      --slide-button-width: 32px;
      &.left { 
        flex-direction: row-reverse;
        button {
          border: none;
          border-left: 1px solid #6565A987;
          .slider-icon {
            transform: rotate(0deg);
          }
        }
      }

      &.closed {
        width: var(--slide-button-width);
        
        &.right {
          button .slider-icon {
            transform: rotate(0deg);
          }
        }
        &.left {
          button .slider-icon {
            transform: rotate(180deg);
          }
        }
      }
      button {
        background: transparent;
        cursor: pointer;
        padding: 0 4px;
        border: none;
        border-right: 1px solid #6565A987;
        .slider-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          transition: ease all 0.3s;
          transform: rotate(180deg);
          color: #6565A9;
        }
      }
      > div {
        padding: 12px 16px 12px 12px;
        min-width: var(--width);
        width: 100%;
      }
    }
    .vendor-trace-prevalence {
      --width: 230px;
      width:  calc(var(--width) + var(--slide-button-width));
      height: 110px;
      right: 74px;
      bottom: 20px;
      .prevalence-icon {
        padding-right: 12px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
      .prevalence-range-slider{
        margin-top: 30px;
        margin-bottom: 18px;
        .ant-slider-track{
          background: #C4C4C4;
          height: 8px;
        }
        .ant-slider-rail {
          background: linear-gradient(to right, #dfdfed 0% 25%,#C1C1DC 25% 50%, #a2a2cb 50% 75%,#8383BA 75% 100%);
          height: 8px;
          &:after{
            width: 14px;
            height: 14px;
            background-color: @primary-medium-color;
            border-radius: 50%;
            content: '';
            display: flex;
            position: relative;
            z-index: 1;
            top: -3px;
            right: 0;
            left: 181px;
          }
        }
        .ant-slider-dot {
          top: 0;
          width: 1px;
          height: 8px;
          border-radius: 0;
          border: none;
        }
        .ant-slider-handle{
          border-color: @primary-color;
          margin-top: 2px;
          z-index: 3
        }
        .ant-slider-mark {
          font-size: 12px;
          .ant-slider-mark-text{
            color: @font-color;
          }
        }
      }
    }
    .vendor-trace-non-disclosed-filter {
      --width: 315px;
      height: 150px;
      bottom: 20px;
      width:  calc(var(--width) + var(--slide-button-width));
      .anticon-filter {
        margin-right: 5px;
      }
      .color-tags-container {
        justify-content: space-around;
        .color-tag {
          margin: 2px 0 4px;
        }
      }
    }
    .filter-block {
      display: flex;
    }
    .checkbox-list{
      .ant-checkbox-checked .ant-checkbox-inner{
        background-color: @primary-color;
        border-color: @primary-color;
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner::after{
        background-color: @primary-color;
      }
      .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
        border-color: @primary-color;
      }
      .ant-checkbox-checked::after{
        border-color: @primary-color;
      }
      .ant-checkbox-wrapper{
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        &::after {
          display: none;
        }
      }
      .ant-checkbox-group-item{
        margin-right: 0;
      }
    }
    
    .color-tags-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 5px;
      .color-tag{
        height: 15px;
        width: 15px;
        background-color: @primary-medium-color;
      }
    }
    .vendor-trace-zoom-container {
      position: fixed;
      bottom: 145px;
      right: 74px;
      border: 0.5px solid #6565A987;
      border-radius: 5px;
      box-shadow: 0px 3px 6px #00000029;
      display: flex;
      align-items: center;
      .ant-btn{
        border: none;
        border-radius: 0;
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        svg {
          fill: @primary-medium-color
        }
      }
      .ant-btn:hover, .ant-btn:focus{
        color: @primary-color;
        &:disabled{
          color: #6565A987;
        }
      }
      span:first-child > .ant-btn {
        border-radius: 5px 0 0 5px;
      }
      .ant-btn:last-child{
        border-radius: 0 5px 5px 0;
      }
      span:not(:last-child) > .ant-btn {
        border-right: 0.5px solid #6565A987;
      }
      .anticon{
        padding: 0;
      }
    }
  }
  button.ant-btn#show_only_searched_vendors.purple,
  button.ant-btn#show_all_vendors.purple {
    .green-branch {
      stroke: #6565a9;
    }
  }
}

.ant-slider-tooltip{
  color: white;
  font-size: 10px;
  padding-bottom: 4px!important;
  .ant-tooltip-inner{
    background-color: @primary-color;
    min-height: 20px;
    padding: 2px 6px;
  }
  .ant-tooltip-arrow{
    width: 16px;
    height: 16px;
  }
  .ant-tooltip-arrow-content::before{
    background: @primary-color;
  }
}

.avocado-modal {
  &.vendor-details--modal {

    .ant-modal-close {
      .ant-modal-close-x {
        margin: 0;
        .anticon {
          vertical-align: 0;
        }
        .anticon-close, .anticon-close svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .ant-modal-title {
      height: 57px;
    }
    .ant-modal-content {
      border-radius: 16px;
    }
    .ant-modal-header {
      background-color: transparent;
      h4 {
        margin: 0;

        .ant-btn.ant-btn-default {
          border: 1px solid #411F90;
          color: #411F90;
          &.ant-btn-icon-only {
            border-radius: 10px;
            padding: 0;
            margin-left: 5px;
            height: 24px;
            width: 24px;
            align-items: center;
            justify-content: center;
            display: inline-flex;

            .anticon {
              width: 12px;
              height: 12px;
            }
          }

        }
      }
    }
    .ant-modal-body {
      a,
      a.section--value {
        color: var(--color-primary);
      }
      .tracking-method-icon {
        margin: 0 5px;
        width: 16px;
        height: 16px;
      }
      .section--row.technologies {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        row-gap: 8px;
        column-gap: 8px;
        width: unset;
        flex-wrap: wrap;
        &.full {
          width: 100%;
        }
      }
      .technology {
        display: inline-block;
        padding: 6.5px 12px;
        color: var(--color-primary-text-color);
        border: 1px solid #ECE8F3;
        background-color: #F5F4F9;
        transition: border-color ease 0.3s;
        border-radius: 20px;
        &.wide {
          padding: 6.5px 24px;
        }
        &:hover {
          border-color: #411F90;
        }
      }
      .section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        row-gap: 16px;
        &--grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 24px;
          width: 100%;
        }
        &--row {
          color: var(--color-secondary-text-color);
          text-overflow: ellipsis;
          overflow: hidden;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          column-gap: 8px;
          width: 100%;
          // text-wrap: nowrap;
        }
        &--value {
          display: inline-block;
          // width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          color: var(--color-primary-text-color);
        }
      }
    }
  }
}
.diagnose {
  &--tooltip {
    .tooltip-content {
      color: #232A58;
      padding: 10px;
      text-wrap: wrap;
    }
    &.prevalence {
      .ant-tooltip-content{
        right: -13px;
      }
    }

    &.ant-tooltip-placement-rightTop,
    &.ant-tooltip-placement-rightBottom,
    &.ant-tooltip-placement-right {
      .ant-tooltip-arrow {
        left: 1px!important;
      }
    }

    &.ant-tooltip-placement-leftTop,
    &.ant-tooltip-placement-leftBottom,
    &.ant-tooltip-placement-left {
      .ant-tooltip-arrow {
        right: 1px!important;
      }
    }

    &.ant-tooltip-placement-topLeft,
    &.ant-tooltip-placement-topRight,
    &.ant-tooltip-placement-top {
      .ant-tooltip-arrow {
        bottom: 1px!important;
      }
    }

    &.ant-tooltip-placement-bottomRight,
    &.ant-tooltip-placement-bottomLeft,
    &.ant-tooltip-placement-bottom {
      .ant-tooltip-arrow {
        top: 1px!important;
      }
    }

    .ant-tooltip-arrow {
      --antd-arrow-background-color: white;
    }
    .ant-tooltip-inner {
      border: 1px solid #CECECE;
      border-radius: 10px;
      background: white;
      color: @font-color;
      padding: 16px;
    }

    &-message {
      color: #3a3a3a;
      font-weight: 400;
      padding: 16px;
    }
  }
}
.diagnose-vendor_trace--filter-selection {
  padding: 0 8px;
  overflow: hidden;
  max-height: 36px;
  width: 100%;
  text-overflow: ellipsis;
  text-wrap: nowrap;

  > * {
    max-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
}