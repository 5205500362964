#avocado {

    .transaction-receipt-container {

        .selection-select {
            width: fit-content;
        }

        // .ant-input-group-addon {
        //     border: 0px;
        //     padding: 0px;
        //     border-radius: 6px;
        //     border: #D3D9DD;
        // }

        .empty-image {
            svg {
                width: 184px;
                height: 113px;
            }

            .ant-empty-description {
                margin-top: 25%;
            }
        }

        .transaction-container {
            height: 100vh;
        }

        .search-container {
            padding: var(--ant-margin);
            gap: 8px;
            border-radius: 4px;
            background: #FFF;
            height: 100%;
            /* boxShadowTertiary */
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);

            .title-text {
                color: var(--Color-Text-Light-bg-Secondary, #4D4D4D);

                /* Heading/H2 20px SeB */
                font-family: "Open Sans";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

        }
    }

}