@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

.properties-container {
    padding-top: 60px;
    font-weight: 300;
    .ant-tabs.ant-tabs-card {
      .ant-tabs-nav {
        .ant-tabs-nav-list {
          height: auto;
          .ant-tabs-tab {
            font-size: 16px;
            margin-right: 0px;
            border: 1px solid #fff;
            color: #4b4b4b;
            padding: 0 16px;
            height: 40px;
          }
          .ant-tabs-tab-active {
            background: #7f92bd;
            border: 1px solid #d9d9d9;
            .ant-tabs-tab-btn{
              color: #fff;
              background: #7f92bd;
            }
          }
          .ant-tabs-tab:first-child {
            border-top-left-radius: 5px;
          }
          .ant-tabs-tab:nth-child(2) {
            border-top-right-radius: 5px;
          }
        }
        .ant-tabs-extra-content {
          margin-top: -12px;
          .ant-btn.ant-btn-primary {
            margin-bottom: 0px;
          }
          .btn-secondary {
            margin-right: 15px;
          }
        }
      }
      .ant-tabs-content {
        .properties-loading {
          position: absolute;
          z-index: 10;
          width: 100%;
          background: transparent;
          .ant-spin {
            display: flex;
            justify-content: center;
            padding: 10%;
            position: relative;
            left: 0;
          }
        }
        .property-group-heading {
          color: #000000a6;
          font-size: 12px;
        }
        .properties-content-container {
          // background: #fff;
          padding: 2%;
          margin-bottom: 3%;
          .site-errors {
            display: flex;
            .ant-alert {
              font: normal 600 12px Open Sans;
              letter-spacing: 0.2px;
              color: #272727;
              opacity: 1;
              margin-bottom: 2%;
              .ant-alert-close-icon {
                position: initial;
                i {
                  color: #f00;
                  font-size: 14px;
                }
              }
            }
            .ant-alert.ant-alert-error{
              background: #FFF1F0 0% 0% no-repeat padding-box;
              border: 1px solid #FFD2CF;
              opacity: 1;
            }
          }
          .properties-table-header {
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin-bottom: 1%;
            .properties-filter{
              display: inline-block;
              .ant-select {
                .ant-select-selector {
                  height: 100% !important;
                  min-height: 32px;
                  .ant-select-selection-item {
                    line-height: 30px !important;
                  }
                }
              }
            }
            .ant-input-search-icon::before{
             border: 0;
            }
          }
          .ant-table {
            border: 1px solid #efefef;
            .ant-table-thead {
              background-color: #f3f8fc;
              border: 1px solid #eeeeee;
              tr {
                background: transparent;
                th {
                  text-transform: capitalize;
                  font-size: 12px;
                  color: #000;
                  font-weight: normal;
                  .ant-table-column-sorter {
                    .ant-table-column-sorter-up:hover,
                    .ant-table-column-sorter-down:hover {
                      color: rgba(0, 0, 0, 0.85) !important;
                    }
                    .ant-table-column-sorter-up.active,
                    .ant-table-column-sorter-down.active {
                        color: rgba(0, 0, 0, 0.85);
                    }
                  }
                }
              }
            }
            td {
              font-size: 12px;
              .action-flex-container {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                min-width: 140px;
              }
              .max-width-property-column {
                max-width: 15vw;
              }
            }
            .ant-table-thead > tr > th {
              background: transparent;
              .ant-table-column-sorter {
                .ant-table-column-sorter-up:hover,
                .ant-table-column-sorter-down:hover {
                  color: rgba(0, 0, 0, 0.85) !important;
                }
                .ant-table-column-sorter-up.active,
                .ant-table-column-sorter-down.active {
                    color: rgba(0, 0, 0, 0.85);
                }
              }
            }
            .ant-table-row{
              cursor: pointer;
            }
          }
        }
        .property-groups-content-container {
          background-color: #fff;
          margin-top: 1%;
          margin-bottom: 2%;
          min-height: 350px;
          .ant-tabs-nav {
            width: 30%;
            min-height: 350px;
            background: #fbfcfe 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #eeeeee;
            padding: 0;
            .ant-tabs-nav-wrap {
              background: #fbfcfe 0% 0% no-repeat padding-box;
              .ant-tabs-nav-list {
                padding-top: 0;
                .ant-tabs-tab-active {
                  background-color: #fff;
                  border: 0;
                }
                .ant-tabs-tab {
                  border: 1px solid #eeeeee;
                  border-bottom: 0;
                  margin: 0;
                  padding: 10px 10px;
                  text-align: left;
                  height: auto;
                  color: #000;
                  border-radius: 0 !important;
                  left: 0 !important;
                  .property-custom-tab {
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    .property-group-name {
                      white-space: initial;
                      word-wrap: break-word;
                      overflow: hidden;
                    }
                    a {
                      color: #000;
                      i {
                        color: #000000a6;
                        font-size: 12px;
                      }
                    }
                    i {
                      align-self: center;
                      color: #b8c2d1;
                    }
                    span {
                      color: #000000a6;
                      font-size: 14px;
                    }
                  }
                  .add-property-group {
                   .ant-input-search-icon::before{
                     border: 0;
                    }
                    button {
                      padding: 6px 12px;
                      margin-bottom: 15px;
                      .anticon {
                        margin-right: 0;
                      }
                      span {
                        margin-left: 0.2em;
                      }
                    }
                    div {
                      font-size: 14px;
                      color: #000000;
                    }
                    .ant-input-affix-wrapper {
                      .ant-input-suffix .anticon.anticon-search {
                        margin-right: 0;
                      }
                    }
                  }
                  .ant-tabs-tab-btn{
                    background-color: transparent;
                    width: 100%;
                  }
                }
                .ant-tabs-ink-bar {
                  width: 0;
                }
                .ant-tabs-tab:first-child {
                  background: #fbfcfe;
                }
              }
            }
          }
          .ant-tabs-content {
            padding: 20px;
            background: #fff;
            .no-properties-wrapper {
              padding: 10% 0;
              color: #00000080;
              font-size: 12px;
              span {
                color: #707070;
                font-size: 14px;
                a {
                  font-family: Open Sans;
                  font-size: 14px;
                  font-weight: 600;
                  letter-spacing: 0px;
                  color: #411F90;
                  white-space: nowrap;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
            .multi-campaign-swithch-group {
              font-size: 12px;
              align-items: center;
              button {
                margin-right: 5px;
              }
            }
            .pg-name {
              font-size: 20px;
              color: #000;
              display: flex;
              justify-content: space-between;
              font-weight: bold;
              .pg-directto-campagins{
                cursor: pointer;
              }
              button {
                border: 0;
                color: #637898;
                font-size: 16px;
              }
              button.edit-property-group {
                .anticon + span {
                  margin-left: 0;
                }
              }
              [ant-click-animating-without-extra-node]:after {
                -webkit-animation: none !important;
                -moz-animation: none !important;
                -o-animation: none !important;
                -ms-animation: none !important;
                animation: none !important;
              }
            }
            .pg-desc {
              font-size: 14px;
              color: #000000a6;
            }
            .pg-header-sub {
              color: #000000;
              font-size: 16px;
              margin-top: 3%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 1%;
              .ant-btn {
                border: 1px solid #1370fb;
                color: #1370fb;
              }
            }
            .properties-list {
              display: flex;
              flex-wrap: wrap;
              i {
                font-size: 12px;
                font-weight: lighter;
              }
              div {
                width: 33.33%;
                font-size: 14px;
                color: #000000a6;
                padding: 1% 0;
                a {
                  color: #000000a6;
                }
              }
            }
            .search-properties {
              margin: 1% 0;
              .ant-input-search-icon::before{
                border: 0;
              }
            }
            .ant-tabs-tabpane.ant-tabs-tabpane-active{
              padding: 0;
            }
            .ant-tabs-tabpane:first-child {
              padding: 1% 0;
              color: #b8c2d1;
              color: #00000080;
              font-size: 11px;
              font-weight: 100;
              div {
                color: #707070;
                font-size: 13px;
                margin-top: 1%;
                button {
                  border: 0;
                  padding: 0 5px;
                  color: #1370fb;
                  font-weight: 100;
                }
              }
            }
          }
        }
      }
    }
    .anticon-info-circle {
      font-size: 14px;
      margin-left: 5px;
    }
    .ant-tabs-content-holder {
      background-color: #fff;
    }
  }
  .create-property-modal,
  .property-group-properties,
  .property-modal {
    font-family: Open Sans;
    .ant-modal-header {
      background-color: #212f43;
      .ant-modal-title {
        color: #fff;
        font-weight: 100;
      }
    }
    .ant-modal-close {
      color: #fff;
    }
    .ant-modal-footer {
      border: 0;
      button:nth-child(2) {
        background-color: #7f92bd;
        border: 1px solid #7f92bd;
        color: #fff;
        font-weight: 100;
      }
    }
    .ant-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      .property-delete-header {
        font-size: 16px;
        margin-bottom: 2%;
        color: #000;
        display: flex;
        align-items: center;
        i {
          color: #f00;
          font-size: 22px;
          margin-right: 1%;
        }
      }
      .property-delete-note {
        font-size: 12px;
        display: flex;
        i {
          font-size: 16px;
          opacity: 0;
          margin-right: 5px;
        }
      }
      .properties-policy-select {
        display: flex;
        flex-direction: column;
        margin-bottom: 2%;
        .document-note {
          font-size: 12px;
          margin-bottom: 1%;
        }
        .select-policy-wrapper {
          display: flex;
          margin-top: var(--ant-margin-xs);
        }
        // .policy-wrapper {
        //   display: flex;
        //   flex-direction: row;
        //   align-items: center;
        //   margin-top: 1%;
        //   flex: 1;
        //   .ant-select {
        //     flex: 0.6;
        //     margin-bottom: 0;
        //     margin-left: 1%;
        //   }
        // }
        .policy-campaigns-wrapper {
          display: flex;
          flex-direction: column;
          margin-top: 1%;
          flex: 1;
          .ant-select {
            flex: 0.6;
            margin-bottom: 0;
            width: 280px;
          }
        }
      .policy-campaigns-wrapper {
          .ant-select {
            .ant-select-selector {
              height: 100%;
              min-height: 32px;
              .ant-select-selection-item {
                line-height: 22px;
              }
            }
          }
        }
        .policy-campaigns-wrapper:first-child {
          margin-right: 2%;
        }
        .ant-checkbox-wrapper {
          margin-left: 1%;
        }
      }
      .ant-input.disabled-code {
        background-color: #fbfbfb;
        color: rgba(0, 0, 0, 0.85);
        -webkit-user-select: none; /* Chrome all / Safari all */
        -moz-user-select: none; /* Firefox all */
        -ms-user-select: none; /* IE 10+ */
        user-select: none; /* Likely future */
        -youbkit-touch-callout: none; /* iOS Safari */
        -youbkit-user-select: none; /* Chrome 6.0+, Safari 3.1+, Edge & Opera 15+ */
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -o-user-select: none;
        font-size: 12px;
      }
      .property-row {
        display: flex;
        flex-direction: column;
        span {
          font: normal 600 13px Open Sans;
          letter-spacing: 0px;
          color: #272727;
          opacity: 1;
          padding-bottom: 5px;
        }
        .ant-input {
          height: 32px;
          font-size: 13px;
          font-weight: 500;
        }
        .ant-select {
          .ant-select-selection--single {
            width: auto;
          }
        }
        .ant-input.description-area{
          font-family: open sans;
        }
        .ant-input.description-area:focus {
          border: 1px solid #43BA54;
          background: #fff;
          box-shadow: none;
        }
        .ant-input.description-area:hover{
          border-color: #43BA54;
        }
        .property-row-custom {
          display: flex;
          flex-direction: column;
          .property-group-selected {
            display: flex;
            .ant-tag {
              height: 32px;
              align-items: center;
              display: flex;
              background: #e0edfe;
              color: #1370fb;
              border-radius: 5px;
              border: 1px solid #e0edfe;
              .anticon-cross {
                color: #1370fb;
              }
            }
          }
        }
      }
    }
  }
  .create-property-modal .ant-modal {
    width: 400px !important;
    margin: auto;
  }
  .property-group-properties {
    .ant-modal {
      width: 65% !important;
      margin: auto;
      .ant-modal-header {
        padding: 16px 1%;
      }
      .ant-modal-body {
        padding: 0;
        font-size: 12px;
        max-height: 430px;
        overflow-y: scroll;
        .property-group-add-properties {
          display: flex;
          .add-properties-list {
            padding: 2%;
            flex: 0.6;
            border-right: 1px solid #f7eeee;
            font-size: 12px;
            .add-properties-header {
              color: #000;
            }
            .add-properties-list{
              border: 0;
              .ant-list-item {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
              }
            }
            .action-items {
              display: flex;
              vertical-align: ce;
              justify-content: center;
              align-items: center;
              justify-content: space-between;
              margin-top: 3%;
              margin-bottom: 3%;
              .ant-input-search-icon::before{
                border: 0;
              }
              .ant-input {
                margin-bottom: 0;
              }
              .anticon-check-circle {
                color: green;
                position: absolute;
                z-index: 10;
                margin-top: -5px;
                margin-left: -4px;
              }
              .anticon-filter {
                font-size: 14px;
                position: relative;
                color: #411F90; 
              }
            }
            .ant-table {
              .ant-table-thead > tr > th {
                background: #f3f8fc;
                padding: 2% 1%;
                text-transform: capitalize;
                font-size: 10px;
                color: #000;
                font-weight: bold;
              }
              tr > td {
                padding: 2% 1%;
                color: #000000a6;
              }
            }
            .anticon-filter {
              cursor: pointer;
            }
          }
          .add-properties-selected {
            padding: 2%;
            flex: 0.4;
            font-size: 12px;
            .selected-properties-header {
              color: #000;
              font-size: 14px;
              margin-bottom: 3%;
            }
            .campaign-setting{
              background: #f1f3f6;
              color: #000000;
              padding: 2%;
              font-size: 10px;
              display: flex;
              align-items: center;
              margin-bottom: 2%;
              .ant-checkbox-wrapper{
                margin-right: 2%;
              }
            }
            .note-text {
              font-size: 10px;
              color: #00000080;
            }
            .ant-list-item {
              padding: 12px 0;
              .ant-list-item-meta-title {
                font-size: 12px;
                margin: 0;
                color: #000000a6;
              }
              .ant-list-item-action {
                margin: 0;
                i {
                  color: #c4c4c4;
                }
              }
            }
          }
        }
      }
    }
  }
    .property-delete-modal {
      .ant-modal {
        min-width: 520px;
        max-width: 60%;
        margin: auto;
        width: fit-content !important;
        .ant-modal-content {
          .ant-modal-body {
            flex: 1;
            display: flex;
            flex-direction: row;
            .property-delete-header {
              font-size: 18px;
              margin-bottom: 2%;
              color: #000;
              font-weight: 600;
              i {
                color: #f00;
              }
              .header-title{
                max-width: 390px;
              }
            }
            .property-delete-icon {
              svg {
                height: 28px;
                width: 32px;
                margin-right: 10px;
                margin-top: 7px;
              }
            }
            .property-delete-note {
              font-size: 14px;
              display: flex;
              i {
                font-size: 16px;
                opacity: 0;
                margin-right: 5px;
              }
            }
          }
        }
        .modal-container {
          .error-message {
            text-align: left;
            font: normal 15px Open Sans;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            padding-bottom: 10px;
          }
          .campaigns-set-wrapper {
          .warning-message{
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #CCE6CF;
            border-radius: 6px 6px 0px 0px;
            opacity: 1;
            padding: 10px;
            text-align: left;
            font: normal 14px Open Sans;
            letter-spacing: 0px;
            color: #000000;
            margin: 0;

            img{
              width: 16px;
              height: 16px;
              margin: 0px 5px 5px 0px;
            }
          }
          .campaigns-sets {
            display: flex;
            background: #E2F4E4 0% 0% no-repeat padding-box;
            border: 1px solid #CCE6CF;
            border-radius: 0px 0px 6px 6px;
            opacity: 1;
            border-top: none;

              .domain-set {
                font: normal 700 13px Open Sans;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                background: #B1D3B5 0% 0% no-repeat padding-box;
                border-radius: 6px;
                padding: 5px 5px;
                margin: 10px;
              }
            }
          }
        }
      }
    }
  .property-group-modal {
    .ant-modal {
      width: fit-content !important;
      .spsg-input.search-properties{
        width: 500px;
        .ant-input {
          margin-bottom: 0;
        }
      } 
    }

    .pg-subtitle {
      color: #00000080;
      font-size: 12px;
      font-weight: 400px;
    }
    .ant-modal-header {
      background-color: #212f43;
      .ant-modal-title {
        color: #fff;
        font-weight: 100;
      }
    }
    .pg-mainContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: auto;
      border: 1px solid #e1e1e1;
      padding: 10px;
      margin-top: 10px;
      background: #fbfbfb;
      .ant-input-search-icon::before{
       border: 0;
      }
      .ant-input-search .ant-input{
       margin-bottom: 0;
      }
    }

    .pg-subContainer {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .pg-search-title {
      flex: 1;
      font: normal 600 13px Open Sans;
      letter-spacing: 0px;
      color: #272727;
      opacity: 1;
    }

    .pg-clear-filter {
      color: #411F90;
      font: normal 600 13px Open Sans;
      letter-spacing: 0px;
      opacity: 1;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }

    .property-count-title {
      margin-top: 10px;
      font: normal 600 13px Open Sans;
      letter-spacing: 0px;
      color: #272727;
      opacity: 1;
    }

    .pg-propertyContainer {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      flex-direction: row;
      height: auto;
      border: 1px solid #e1e1e1;
      padding: 10px;
      margin-top: 10px;
    }

    .pg-propertyItem {
      //flex: 1;
      width: 24.33%;
      align-items: center;
      color: #000000a6;
      padding-bottom: 15px;
    }

    .pg-propertyIcon {
      margin-left: 5px;
      color: #c4c4c4;
    }

    .pg-select-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .pg-title {
      font: normal 600 13px Open Sans;
      letter-spacing: 0px;
      color: #272727;
      opacity: 1;
    }

    .removeAll-title {
      color: #411F90;
      font: normal 600 13px Open Sans;
      letter-spacing: 0px;
      opacity: 1;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }

    .pg-select {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-top: 10px;

      .ant-select {
        width: 150px;
        .ant-select-selection--single {
          width: auto;
        }
      }
    }
    .pg-select-label {
      font: normal 600 12px Open Sans;
      letter-spacing: 0px;
      color: #272727;
      opacity: 1;
      margin-left: 20px;
      margin-right: 5px;
    }
    .ant-modal-close {
      color: #fff;
    }
    .ant-modal-footer {
      border: 0;
      button:nth-child(2) {
        background-color: #7f92bd;
        border: 1px solid #7f92bd;
        color: #fff;
        font-weight: 100;
      }
    }
    .ant-modal-body {
      flex: 1;
      display: flex;
      flex-direction: column;
      .properties-policy-select {
        display: flex;
        align-items: center;
        margin-bottom: 2%;
        span {
          flex: 0.1;
        }
        .ant-select {
          flex: 0.5;
          margin-bottom: 0;
        }
      }
      .property-row {
        display: flex;
        flex-direction: column;
        span {
          font: normal 600 13px Open Sans;
          letter-spacing: 0px;
          color: #272727;
          opacity: 1;
          padding-bottom: 5px;
        }
        .ant-input {
          width: 50%;
          height: 32px;
          margin-bottom: 15px;
          font-size: 13px;
          font-weight: 500;
        }
        .ant-select {
          width: 50%;
          .ant-select-selection--single {
            width: auto;
          }
        }
        .ant-input.description-area{
          font-family: open sans;
        }
        .ant-input.description-area:focus {
          border: 1px solid #43BA54;
          background: #fff;
          box-shadow: none;
        }
        .ant-input.description-area:hover{
          border-color: #43BA54;
        }
        .property-row-custom {
          display: flex;
          .ant-tag {
            height: 32px;
            align-items: center;
            display: flex;
            margin-left: 2%;
            background: #e0edfe;
            color: #1370fb;
            border-radius: 5px;
            border: 1px solid #e0edfe;
            .anticon-cross {
              color: #1370fb;
            }
          }
        }
      }
    }
  }

  .property-filter-popover {
    .filter-title{
      font-size: 13px;
    }
    .ant-popover-title {
      display: flex;
      justify-content: space-between;
      padding: 6px 16px;
      font-size: 12px;
      align-items: center;
      button {
        border: 0;
        padding: 0;
        span {
          color: #411F90;
          font: normal 600 13px Open Sans;
          letter-spacing: 0px;
          opacity: 1;
          cursor: pointer;
          white-space: nowrap;
          &:hover {
            text-decoration: underline;
          }
        } 
      }
      span {
        font-weight: 600;
        color: #3e3e3e;
        padding: 0;
      }
    }
    .spsg-select {
      .ant-select {
        width: 100%;
        .ant-select-selector {
          box-shadow: none;
          outline: 0;
          border: 1px solid #DAE4EB;
          border-radius: 3px;
        }
        &:hover .ant-select-selector, &:focus .ant-select-selector {
          border: 1px solid #DAE4EB;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
          outline: 0;
        }
        &.ant-select-open.ant-select-show-search{
          border: 1px solid #43BA54;
        }
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #DAE4EB;
      }
    }
    .ant-checkbox-group {
      margin-bottom: 1%;
      .ant-checkbox-wrapper {
        font-size: 12px;
      }
    }
    .ant-radio-group {
      margin-bottom: 3%;
      .ant-radio-button-wrapper {
        margin-right: 10px;
        border-radius: 5px;
        background: #F5F5F5;
        font-family: Open Sans;
        font-size: 13px;
        letter-spacing: 0px;
        color: #2D2D2D;
        height: 34px;
        line-height: 34px;
        border: 1px solid #d9d9d9;
        &:before {
          display: none;
        }
        &.ant-radio-button-wrapper-checked {
          background: #fff;
          border-color: #411F90;
          border: 1px solid #411F90;
        }
      }
    }
    .property-filter-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 3%;
      button.ant-btn {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #090E15;
        padding: 9px 20px;
        height: 40px;
        box-shadow: none;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        border-radius: 5px;
        margin-left: 20px;
        min-width: 92px;
        &:hover {
          color: #090E15;
          background: #F5F7FB;
          border: 1px solid #D7DDE9;
        }
        &:focus {
          color: #090E15;
          background: #E7ECF4;
          border: 1px solid #E7ECF4;
        }
        &[disabled] {
          opacity: 1.0;
          color: #ADB5C5;
          background: #E7ECF4;
          border: 1px solid #E7ECF4;
        }
        > svg {
          font-size: 16px;
          margin-right: 10px;
        }
        &.ant-btn-primary, &.btn-primary {
          background: #411F90;
          border: 1px solid #411F90;
          color: #fff;
          &:hover {
            color: #fff;
            background: #6565A9;
            border: 1px solid #6565A9;
          }
          &:focus {
            color: #fff;
            background: #6565A9;
            border: 1px solid #6565A9;
          }
          &[disabled] {
            opacity: 0.6;
          }
        }
      }
    }
  }

  .search-popover {
    position: fixed;
    top: 480px !important;

    .ant-popover-arrow {
      display: none;
    }

    .popover-content-container {
      flex: 1;
      display: flex;
    }

    .popover-content-title {
      flex: 1;
      font-size: 13px;
      font-family: Open Sans;
      font-weight: 600;
    }

    .ant-popover-inner-content {
      width: 500px;
      overflow-y: scroll;
      // height:350px;
    }

    .removeAll-title {
      color: #411F90;
      font: normal 600 13px Open Sans;
      letter-spacing: 0px;
      opacity: 1;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .multi-campaign-succes-message {
    .ant-message-success, .ant-message-error {
      display: flex;
      span span {
        font-weight: bold;
      }
    }
  }

  .multi-campaign-tooltip {
    .ant-tooltip-content .ant-tooltip-inner {
      span {
        font-weight: bold;
      }
      color: black;
      width: 400px;
    }
  }

  .multi-campaign-messages {
      font-size: 12px; 
      .warning {
        display: flex;
        border: 1px solid #ff7878c9;
        background-color: #ff78784a;
        margin-bottom: 10px;
        span {
          font-weight: bold;
        }
        span.anticon {
          color: #ff7878c9;
          padding: 10px 0 10px 10px;
          font-size: 18px;
        }
        .message {
          padding: 5px 10px 20px;
        }
      }
    }

  .multi-campaign-messages.large {
    margin-top: -40px;
    padding-top: 0;
    margin-bottom: 15px;
    .message{
      padding-top: 20px;
    }
    font-size: 14px;
    .container {
      border: 1px solid #ff7878c9;
      background-color: #ff78784a;
      .warning {
        border: none;
        background-color: transparent;
      }
    }
    span.anticon {
      color: #ff7878c9;
      padding: 20px 0 20px 0px;
      font-size: 22px;
    }
    .close {
      font-size: 25px;
      cursor: pointer;
    }
    .snippet-link {
      padding-left: 5px;
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }
    button {
      margin-bottom: 20px;
    };
  }
