//universal figma styleguide override
.avocado {
  --ant-violet-1: #eae4f2;
  --ant-violet-2: #d3c5e6;
  --ant-violet-3: #b198d9;
  --ant-violet-4: #8f6ecc;
  --ant-violet-5: #6e49bf;
  --ant-violet-6: #4f28b3;
  --ant-violet-7: #35188c;
  --ant-violet-8: #200c66;
  --ant-violet-9: #0f0440;
  --ant-violet-10: #05021a;
  
  --ant-green-1: #F7FAF4;
  --ant-green-2: #bed9a7;
  --ant-green-3: #9fcc7c;
  --ant-green-4: #80bf56;
  --ant-green-5: #62b334;
  --ant-green-6: #46a716;
  --ant-green-7: #2d800a;
  --ant-green-8: #2A610F;
  --ant-green-9: #0c3300;
  --ant-green-10: #030d00;

  --ant-gold-1: #FFFEF5;
  --ant-gold-2: #fae9a5;
  --ant-gold-3: #edd177;
  --ant-gold-4: #e0b94c;
  --ant-gold-5: #d4a026;
  --ant-gold-6: #c88704;
  --ant-gold-7: #a16600;
  --ant-gold-8: #775103;
  --ant-gold-9: #543000;
  --ant-gold-10: #2e1800;
}
// overriding ants default padding of Tag as it does not match the design even after providing the right tokens. 
.avocado.ant-tag {
  padding: 1px 8px;
  .ant-tag-close-icon {
    margin-left: 8px;
    color: inherit;
    font-size: 12px;
  }
  img {
    vertical-align: middle;
  }
}

//close wizard button
button.close-wizard-btn.ant-btn-icon-only{
  border: 0;
  &.ant-btn-default {
    color: var(--ant-color-icon);
    &:hover, &:focus{
      color: var(--ant-color-primary-hover)
    }
    &:active {
      color: var(--ant-color-primary-active);
    }
  }
}

//modal close button
.ant-modal-wrap {
  --ant-modal-footer-padding: 16px;
  button.ant-modal-close {
    .ant-modal-close-x{
      color: var(--ant-color-icon);
    }
    &:hover, &:focus{
      .ant-modal-close-x{
        color: var(--ant-color-primary-hover);
      }
      background-color: transparent;
    }
    &:active {
      .ant-modal-close-x{
        color: var(--ant-color-primary-active);
      }
    }
  }
}

//Tabs font weight and icon
.avocado.ant-tabs-css-var, .ant-tabs {
  --ant-tabs-horizontal-margin: 0;
  .ant-tabs-tab-active {
    font-weight: var(--ant-font-weight-strong);
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab-btn {
      display: flex;
      >.ant-tabs-tab-icon {
        margin-inline-end: 0;
        margin-inline-start: var(--ant-margin-sm);
        order: 2;
      }
    }
  }
}

//selected menu item font weight
.ant-menu {
  .ant-menu-item-selected, .ant-menu-submenu-selected {
    font-weight: 600;
  }
}

//typography
.ant-typography {
  mark {
    background-color: var(--ant-color-primary-bg);
  }
  div& {
    margin-bottom: 0;
  }

  h1&,h2&,h3&,h4&,h5&,h6& {
    margin-top: 0;
  }
}

//Input 
.ant-input-affix-wrapper.avocado {
  .ant-input-suffix {
    margin-inline-start: var(--ant-margin-xs);
  }
}

//Table 
.ant-table-wrapper {
  .ant-table-row-expand-icon{
    border-radius: 2px;
  }
}

//date-picker
.ant-picker-dropdown {
  .ant-picker-footer {
    .ant-picker-now-btn {
      color: var(--ant-color-info);
    }
  }
  .ant-picker-time-panel {
    .ant-picker-header {
      .ant-picker-header-view {
        display: inline-block; /* Ensure it's treated as a block/inline-block element */
        color: transparent; /* Hide the original text */
        position: relative;
        &::before {
          content: "Time (HH:MM:SS)";
          color: var(--ant-color-text-base); /* Show new content */
          position: absolute;
          top: 0;
          left: 0;
          line-height: 22px;
          padding: var(--ant-padding-xs) var(--ant-padding);
        }
      }
    }
  }
}

//alert 
.ant-alert-content {
  text-align: left;
}

//ant-tree 
.ant-tree-select-dropdown {
  .ant-select-tree {
    .ant-select-tree-switcher {
      .ant-select-tree-switcher-icon svg {
        position: relative;
      }
    }
  }
}   